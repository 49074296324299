(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name services
   * @description
   *
   */
  angular
  .module('neo.services', [
    'ngResource',
    'neo.config',
    'neo.services.auth'
  ]);
}());
